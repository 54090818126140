import React from "react";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import { setSelectedMethod } from "@/lib/features/slices/paymentMethodSlice";
import PaymentModal from "./paymentModal";

const PaymentMethodsForm = () => {
  const store = useAppStore();
  const { selectedMethod } = useAppSelector((state) => state.paymentMethod);

  const handleOptionChange = (value: string) => {
    store.dispatch(setSelectedMethod({ name: "selectedMethod", value }));
  };
  return (
    <>
      <PaymentModal />
      <form className="form-center mt-5">
        {["creditCard", "applePay", "googlePay", "mobilePay"].map((method) => (
          <div
            key={method}
            className={`payment-methods  radio-btn-field flex gap-5 mb-5 ${
              selectedMethod === method && "selected-method"
            }`}
            onClick={() => handleOptionChange(method)}
          >
            <div className="flex gap-5">
              <input
                type="radio"
                value={method}
                checked={selectedMethod === method}
                onChange={() => handleOptionChange(method)}
              />
              <p>{method.charAt(0).toUpperCase() + method.slice(1)}</p>
            </div>
            <img alt={`${method} logo`} src={`/images/${method}.svg`} />
          </div>
        ))}
      </form>
    </>
  );
};

export default PaymentMethodsForm;
