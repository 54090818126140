import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import PaymentGateway from "./payment/paymentComponent";
import { resetForm, showModal } from "@/lib/features/slices/paymentSlice";

export default function PaymentModal() {
  const store = useAppStore();
  const state = useAppSelector((state) => state.paymentForm);
  const cancelButtonRef = useRef(null);

  return (
    <div className="wrapper overflow-x-auto">
      <Transition.Root show={state.open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 w-screen overflow-x-auto"
          style={{ backgroundColor: "#FFFAFA" }}
          initialFocus={cancelButtonRef}
          onClose={() =>
            store.dispatch(showModal(false), store.dispatch(resetForm()))
          }
        >
          <div className="flex items-start justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel>
                <div className="paymentModal ">
                  <div className="p-5 ">
                    <div
                      className="close-screen-icon"
                      onClick={() =>
                        store.dispatch(
                          showModal(false),
                          store.dispatch(resetForm())
                        )
                      }
                    >
                      <img src={"./images/Exit_icon_buton.svg"} />
                    </div>
                    <Dialog.Title as="h3" className=" payment-title">
                      Proceed with <br />
                      another payment <br />
                      method
                      <p>Please provide your credit card details.</p>
                    </Dialog.Title>
                    <div>
                      <PaymentGateway />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
